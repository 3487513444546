import { SelectorDevicesComponent } from "../selectorDevices/selectorDevices.component";
import { SelectorMediaComponent } from "../selectorMedia/selectorMedia.component";
import { SelectorStreamsProperties } from "./selectorStreams.properties";

import "../selectorDevices/selector.styles.scss";

/**
 * Show media and devices selector in the same component to avoid multiple components
 *
 * @param props
 * @returns
 */
export default function SelectorStreams(props: SelectorStreamsProperties) {
  return (
    <div className="SelectorComponent">
      <SelectorMediaComponent
        deviceInstance={props.deviceInstance}
        setScene={props.setScene}
        user={props.user}
        addStream={props.addStream}
      />

      <SelectorDevicesComponent
        deviceInstance={props.deviceInstance}
        localScreams={props.localScreams || []}
        mode={props.mode || "devices"}
        setScene={props.setScene}
        user={props.user}
        addStream={props.addStream}
        removeStream={props.removeStream}
      />
    </div>
  );
}
