// Modules
import { Fragment, useEffect, useState } from 'react';

// Properties
import { CreatorProperties } from './creator.properties';

// Components
import { LoaderComponent } from '../../../loader/loader.component';
import { VideoComponent } from './creator.video';

// styles
import './creator.styles.scss'
import { useTranslation } from 'react-i18next';

// Helpers
import { UserSettingsHelper } from '../../../../helpers/UserSettingsHelper';

/**
 * Creator Component
 * @param {CreatorProperties} properties
 * @returns {JSX.Element}
 */
export function CreatorComponent(properties: CreatorProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // have default video
    const [haveDefaultVideo, setHaveDefaultVideo] = useState<boolean>(false)

    // loading
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      if (!loading) {
        properties.setDevicesLoaded(true);
      }
    }, [loading]);

    // create random room id
    const makeRandomRoomId = (length: number) => {

        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }

        return result;

    }

    const createRoom = () => {

        // random room id
        const randomId = makeRandomRoomId(10)

        // emit create room
        properties.sockerInstance.emit('room:create', {
            room: randomId,
            user: properties.user
        })

    }

    useEffect(() => {

        // If Socket is Connected check if Default Video is enabled
        if (properties.sockerInstance.status === 'connected' && !haveDefaultVideo) {


            setTimeout(async () => {

                // get videoDeviceId from async storage
                const videoDeviceId = localStorage.getItem('videoDeviceId')

                // get audioDeviceId from async storage
                const audioDeviceId = localStorage.getItem('audioDeviceId')

                // get background from async storage
                const withBackground = localStorage.getItem('backgroundImage')

                let newStream = undefined;
                if (videoDeviceId && audioDeviceId) {

                    // search device with videoDeviceId and audioDeviceId
                    newStream = await properties.deviceInstance?.getDeviceMedia(
                        {
                            audioId: audioDeviceId,
                            videoId: videoDeviceId,
                        },
                        {
                            noise: true,
                            echo: true,
                            frameRate: 15,
                            ptz: true,
                        }
                    ).catch((error) => {
                        console.log('Error in Creator Component', error)
                    });
                }

                if (!newStream) {

                    // get default device
                    newStream = await properties.deviceInstance?.getDefaultDevice();

                }

                if (newStream) {

                    // delay 1 second, to avoid video flickering
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000);

                    if (withBackground) {

                        // create image base64 background black
                        const imageBlack = new Image();
                        imageBlack.src = withBackground;

                        // replace background
                        const streamBackground = await properties.deviceInstance?.replaceBackground(newStream, imageBlack.src);

                        // add stream to socket
                        streamBackground && properties.sockerInstance?.addLocalStream(streamBackground);

                    }
                    else {

                        // add stream to socket
                        properties.sockerInstance?.addLocalStream(newStream);
                    }

                    // set have default video
                    setHaveDefaultVideo(true);

                }

            }, 0);

        }

    }, []);

    // count users availables
    const filterAvailables = properties.availables.filter((available) => available?.user?.id !== properties?.user?.id)

    return <div className="CreatorComponent ">
        <div className='CreatorComponentBox'>
            <div className={`camera ${loading ? 'loading' : 'active'}`} >
                {
                    properties.localStreams?.map((stream, index) => {
                        return <Fragment key={stream.id}>
                            <LoaderComponent status={loading} />
                            <VideoComponent
                                loading={loading}
                                setLoading={setLoading}
                                deviceInstance={properties.deviceInstance}
                                socketInstance={properties.sockerInstance}
                                stream={stream}
                                userSettings={properties.userSettings}
                                setAudioStatus={properties.setAudioStatus}
                                setVideoStatus={properties.setVideoStatus}
                                setLocalStreams={properties.setLocalStreams}
                            />
                        </Fragment>
                    })
                }
                {
                    properties.localStreams?.length === 0 && <VideoComponent
                        loading={loading}
                        setLoading={setLoading}
                        deviceInstance={properties.deviceInstance}
                        socketInstance={properties.sockerInstance}
                        userSettings={properties.userSettings}
                        setAudioStatus={properties.setAudioStatus}
                        setVideoStatus={properties.setVideoStatus}
                        setLocalStreams={properties.setLocalStreams}
                    />
                }
            </div>
            <div className='form'>
                <h3>{t('creator.title')}</h3>
                <br />
                <div className='creator-buttons'>
                    <button className="dial" disabled={loading} onClick={() => {
                        properties.setLocalCall(false)
                        localStorage.setItem('localCall', 'false')

                        createRoom()

                        properties.setScene('participants')
                    }}>
                        {t('creator.start')}
                    </button>

                    {
                        // If the use is not a doctor and in the array of buttons is the creator.video button
                        // then show the button creator.video only if is on electron environment
                        // @ts-ignore
                        // window.electron &&
                        !properties.userSettings?.is_doctor &&
                        UserSettingsHelper.checkUserSettingsButton(properties.userSettings, 'creator.video_local') &&
                        <button className="localDial" disabled={loading} onClick={() => {
                          properties.setLocalCall(true)
                          localStorage.setItem('localCall', 'true')

                          createRoom()

                          properties.setScene('local-video')
                          properties.setScene('selector')

                        }
                        }>
                            {t('creator.video')}
                        </button>
                    }
                </div>
                {/* <p>
                    {t('creator.users.start')} <b>{filterAvailables.length} {t('creator.users.middle')}</b> {t('creator.users.end')}
                </p> */}
            </div>
        </div>
    </div >
}
