export const translations = {
    resources: {
        es: {
            translation: {
                "login.title.start": "Inicia sesión en ",
                "login.title.end": "con tu email y contraseña",
                "login.email": "Email",
                "login.password": "Contraseña",
                "login.submit": "Iniciar Sesión",
                "login.alternative": "o también puedes crear una cuenta",
                "login.register": "Registrarse",
                "login.forgot": "¿Olvidaste tu contraseña?",
                "forgot.title": "Recuperar Contraseña",
                "forgot.email": "Email",
                "forgot.submit": "Enviar",
                "forgot.login": "Volver a Iniciar Sesión",
                "restore.title": "Restablecer Contraseña",
                "restore.password": "Nueva contraseña",
                "restore.repeatPassword": "Repetir Contraseña",
                "restore.submit": "Restablecer Contraseña",
                "restore.passwordsEmpty": "Las contraseñas no pueden estar vacías",
                "restore.passwordsDontMatch": "Las contraseñas no coinciden",
                "register.title.start": "Regístrate en ",
                "register.title.end": "y accede a todos los servicios",
                "register.success": " Registro completado correctamente. El equipo administrativo de Sippar se pondrá en contacto contigo para activar tu cuenta",
                "register.success.alternative": "Una vez activada tu cuenta podrás <br /> acceder a todos los servicios de <b>Sippar</b>",
                "register.login": "Iniciar Sesión",
                "register.invitation": "Código de invitación",
                "register.name": "Nombre",
                "register.surname": "Apellidos",
                "register.email": "Email",
                "register.submit": "Registrarse",
                "register.password": "Contraseña",
                "register.repeatPassword": "Repetir Contraseña",
                "register.legal": "Acepto",
                "register.conditions": "las condiciones",
                "register.alternative": "o si ya tienes una cuenta",
                "schedule.title": "Teleconsultas",
                "schedule.add": "AÑADIR",
                "schedule.jumpstep": "SALTAR PASO",
                "schedule.future": "Próximas",
                "schedule.past": "Pasadas",
                "schedule.search": "Buscar...",
                "schedule.empty": "No hay resultados",
                "schedule.delete.title": "Eliminar teleconsulta",
                "schedule.delete.success": "La teleconsulta ha sido eliminada",
                "schedule.delete.information": "Solamente el autor de la cita puede eliminarla. Una vez eliminada la información no se podrá recuperar, aunque ",
                "schedule.delete.information2": " cuenta con guardado perpetuo de la información para la seguridad de los datos",
                "schedule.delete.submit": "Eliminar teleconsulta",
                "schedule.insert.back": "Volver",
                "schedule.insert.success": "La teleconsulta se ha creado correctamente",
                "schedule.insert.subject": "Asunto",
                "schedule.insert.start": "Inicio",
                "schedule.insert.end": "Final",
                "schedule.insert.length": "Duración",
                "schedule.insert.info": "Rellene los siguientes campos para crear una teleconsulta:",
                "schedule.insert.info.subject": "El asunto debe tener un mínimo de 5 caracteres y un máximo de 500",
                "schedule.insert.info.date": "La fecha y hora de inicio debe ser posterior a la fecha y hora actuales",
                "schedule.insert.info.length": "La duración mínima es de 10 minutos y la máxima de 3 horas",
                "schedule.insert.submit": "Crear teleconsulta",
                "schedule.select.patient.title": "Selecciona el Paciente",
                "schedule.select.patient.search": "Buscar...",
                "schedule.select.patient.empty": "No hay resultados",
                "schedule.select.search": "Buscar...",
                "schedule.select.title": "Participantes",
                "schedule.select.error": "Sucedió un error al cargar los participantes de la teleconsulta",
                "schedule.select.errorbtn": "Volver a intentar",
                "schedule.update.back": "Volver",
                "schedule.update.participants": "Participantes",
                "schedule.update.success": "Teleconsulta actualizada",
                "schedule.update.subject": "Asunto",
                "schedule.update.start": "Inicio",
                "schedule.update.end": "Final",
                "schedule.update.submit": "GUARDAR",
                "directory.title": "Pacientes",
                "directory.add": "AÑADIR",
                "directory.search": "Buscar...",
                "directory.empty": "No hay resultados",
                "directory.delete.title": "Eliminar Paciente",
                "directory.delete.success": "Paciente eliminado",
                "directory.delete.information": "Solamente los autor del paciente puede eliminar la ficha del paciente. Una vez eliminada la información no se podrá recuperar, aunque <b>Sippar</b> cuenta con guardado perpetuo de la información para la seguridad de los datos",
                "directory.delete.submit": "Eliminar Paciente",
                "directory.insert.title": "Nuevo Paciente",
                "directory.insert.success": "El paciente se ha creado correctamente",
                "directory.insert.name": "Nombre",
                "directory.insert.surname": "Apellidos",
                "directory.insert.document": "Identificación",
                "directory.insert.information": "Detalles Personales",
                "directory.insert.born": "Fecha de Nacimiento",
                "directory.insert.occupation": "Ocupación",
                "directory.insert.observations": "Observaciones",
                "directory.insert.history": "Historial Médico",
                "directory.insert.history.date": "Fecha",
                "directory.insert.history.type": "Tipo de Apunte",
                "directory.insert.history.type.1": "Telemática",
                "directory.insert.history.type.2": "Presencial",
                "directory.insert.history.type.3": "Revisión de Historial",
                "directory.insert.history.type.4": "Análisis de Resultados",
                "directory.insert.history.delete": "Borrar Entrada",
                "directory.insert.history.add": "Añadir Entrada",
                "directory.insert.address": "Dirección",
                "directory.insert.street": "Calle",
                "directory.insert.city": "Ciudad",
                "directory.insert.zip": "Código Postal",
                "directory.insert.country": "País",
                "directory.insert.contact": "Contacto",
                "directory.insert.email": "Email",
                "directory.insert.phone": "Teléfono",
                "directory.insert.phisic": "Detalles Fisiológicos",
                "directory.insert.genre": "Género",
                "directory.insert.genre.1": "Hombre",
                "directory.insert.genre.2": "Mujer",
                "directory.insert.genre.3": "Otro",
                "directory.insert.height": "Altura",
                "directory.insert.height.placeholder": "Medida en cm",
                "directory.insert.size": "Peso",
                "directory.insert.size.placeholder": "Medida en kg",
                "directory.insert.submit": "Crear Paciente",
                "directory.update.title": "Editar Paciente",
                "directory.update.success": "Modificado correctamente",
                "directory.update.name": "Nombre",
                "directory.update.surname": "Apellidos",
                "directory.update.document": "Identificación",
                "directory.update.information": "Detalles Personales",
                "directory.update.born": "Fecha de Nacimiento",
                "directory.update.occupation": "Ocupación",
                "directory.update.observations": "Observaciones",
                "directory.update.history": "Historial Médico",
                "directory.update.history.date": "Fecha",
                "directory.update.history.type": "Tipo de Apunte",
                "directory.update.history.type.1": "Telemática",
                "directory.update.history.type.2": "Presencial",
                "directory.update.history.type.3": "Revisión de Historial",
                "directory.update.history.type.4": "Análisis de Resultados",
                "directory.update.history.delete": "Borrar Entrada",
                "directory.update.history.add": "Añadir Entrada",
                "directory.update.address": "Dirección",
                "directory.update.street": "Calle",
                "directory.update.city": "Ciudad",
                "directory.update.zip": "Código Postal",
                "directory.update.country": "País",
                "directory.update.contact": "Contacto",
                "directory.update.email": "Email",
                "directory.update.phone": "Teléfono",
                "directory.update.phisic": "Detalles Fisiológicos",
                "directory.update.genre": "Género",
                "directory.update.genre.1": "Hombre",
                "directory.update.genre.2": "Mujer",
                "directory.update.genre.3": "Otro",
                "directory.update.height": "Altura",
                "directory.update.height.placeholder": "Medida en cm",
                "directory.update.size": "Peso",
                "directory.update.size.placeholder": "Medida en kg",
                "directory.update.submit": "Editar Paciente",
                "call.title": "Llamada",
                "call.message": "Has recibido una solicitud para unirte a una videoconferencia. Acepta la llamada y accede a la emisión",
                "call.accept": "Aceptar",
                "call.reject": "Rechazar",
                "call.rec.info": "La siguiente llamada va a ser grabada",
                "call.auto": "La llamada se iniciará automáticamente en",
                "controls.speed": "Velocidad",
                "controls.gallery": "Galería",
                "controls.share.stop": "Dejar de presentar",
                "controls.share.another": "Presentar otra cosa",
                "controls.focus": "Focus",
                "creator.title": "Iniciar teleconsulta",
                "creator.subtitle": "Crea una teleconsulta e invita al resto de participantes. Podrás participar con múltiples dispositivos conectados",
                "creator.start": "Llamar",
                "creator.video": "Exploración local",
                "creator.users.start": "Hay",
                "creator.users.middle": "usuarios",
                "creator.users.end": "disponibles",
                "creator.micro.selector": "Selección de microfono",
                "creator.micro.no_devices": "Dispositivo de voz no encontrado",
                "creator.micro.placeholder": "Seleccione un canal",
                "creator.video.selector": "Selección de cámara",
                "creator.video.no_devices": "Dispositivo de video no encontrado",
                "creator.video.placeholder": "Seleccione un canal",
                "creator.background.selector": "Selección de fondo",
                "creator.update": "ACTUALIZAR",
                "info.device.not.available": "No se pudo obtener el dispositivo de audio/video por defecto, por favor verifique que el dispositivo este conectado y no este siendo usado por otra aplicación",
                "media.selector.fail": "No se puede localizar la aplicación {{appName}}, por favor, asegúrese de que la aplicación esté abierta y vuelva a intentarlo",
                "media.selector.wait": "Esperando a la aplicación de {{appName}}...",
                "media.selector.success": "Compartiendo {{appName}}",
                "error.title": "Sin conexión",
                "error.description": "No se pudo conectar con el servidor. Por favor, revise su conexión a internet.",
                "error.button": "Volver a intentar",
                "force.title": "¿Quiere cerrar sesión?",
                "force.description": "No se puede iniciar sesión en dos dispositivos al mismo tiempo. Si desea iniciar sesión en este dispositivo, debe cerrar sesión en el otro dispositivo",
                "force.yes": " Utilizar este dispositivo",
                "force.no": "Cerrar sesión",
                "participants.title": "Participantes",
                "participants.actives": "Activos",
                "participants.availables": "Disponibles",
                "participants.empty": "No hay participantes",
                "participants.allUsers": "Todos los usuarios",
                "dish.chat.form.placeholder": "Escribe un mensaje a todos...",
                "selector.empty": "",
                "selector.share.title": "Compartir",
                "selector.share.subtitle": "Pantalla",
                "settings.status": "Estado de Stream",
                "settings.audio.placeholder": "Cambiar canal de audio",
                "settings.video.placeholder": "Cambiar canal de vídeo",
                "settings.background.placeholder": "Cambiar fondo de vídeo",
                "settings.background.delete": "Quitar fondo",
                "settings.title": "Configuración",
                "settings.video": "Estado del Video",
                "settings.rate": "Frecuencia de fotogramas",
                "settings.aspect": "Relación de aspecto",
                "settings.size": "Tamaño",
                "settings.audio": "Estado del Audio",
                "settings.muted": "Volumen",
                "settings.noiseSuppression": "Supresión de ruido",
                "settings.sampleRate": "Frecuencia de muestreo",
                "settings.sampleSize": "Tamaño de muestra",
                "settings.echoCancellation": "Cancelación de eco",
                "settings.latency": "Latencia",
                "settings.autoGainControl": "Control automático de ganancia",
                "settings.stream": "Estado del Stream",
                "settings.enabled": "Estado del Stream",
                "settings.readyState": "Estado de la conexión",
                "settings.kind": "Tipo de Stream",
                "settings.name": "Nombre del Stream",
                "settings.audio.selector": "Puedes seleccionar el canal de salida de audio de tu dispositivo, para que puedas escuchar la llamada en el dispositivo que desees",
                "settings.audioInput.selector": "Puedes seleccionar el canal de entrada de audio de tu dispositivo, para que puedas compartir el micrófono que desees",
                "settings.video.selector": "Puedes seleccionar el canal de entrada de video de tu dispositivo, para que puedas compartir la cámara que desees",
                "settings.background.selector": "Puedes seleccionar el fondo que desees compartir en la llamada, para que puedas personalizar tu entorno",

                // ========== START: Button Titles ==========
                "button.video.screenshot.title": "Hacer una caputra",
                "button.video.toggle.title": "Activar/Desactivar video",
                "button.video.audio.toggle.title": "Activar/Desactivar audio",
                "button.video.ptz.control.title": "Mostar/Ocultar controles PTZ",
                "button.video.ptz.flip.title": "Girar la cámara PTZ",
                "button.video.status.title": "Configuración",
                "button.video.settings.title": "Configuración",

                "button.calendar.title": "Programar una teleconsulta en el calendario",
                "button.notes.title": "Gestionar pacientes",
                "button.info.title": "Información de conexión",
                "button.share.title": "Compartir equipos médicos y aplicaciones",
                "button.directShare.title": "Compartir pantalla",
                "button.participants.title": "Listado de participantes",
                "button.chat.title": "Chat",
                "button.edit.title": "Editar perfil",
                "button.fullscreen.title": "Pantalla completa",
                "button.exit_room.title": "Salir de la sala",
                "button.exit_call.title": "Salir de la llamada",
                "button.logout.title": "Cerrar sesión",
                "button.modes.title": "Cambiar modo de visualización",
                "register.password.info": "La contraseña debe contener:",
                "register.password.info.length": "Al menos 8 caracteres",
                "register.password.info.uppercase": "Al menos una letra mayúscula",
                "register.password.info.lowercase": "Al menos una letra minúscula",
                "register.password.info.number": "Al menos un número",
                "register.password.info.special": "Al menos un carácter especial",

                "edit.profile.title": "Editar Perfil",
                "edit.profile.name": "Editar perfil",
                "edit.profile.password": "Cambiar contraseña",
                "edit.profile.others": "Ajustes de usuario",
                "edit.profile.edit.name": "Nombre",
                "edit.profile.edit.surnames": "Apellidos",
                "edit.profile.settings.autoCall": "Respuesta automática",
                "edit.profile.settings.autoCallTimeout": "Tiempo de respuesta",

                "edit.profile.form.required": "El campo es obligatorio",

                "edit.profile.settings.autoCall.on": "Activado",
                "edit.profile.settings.autoCall.off": "Desactivado",

                "edit.profile.settings.autoCallTimeout.option": "{{ seconds }} segundos",

                "edit.password.required": "La nueva contraseña es obligatoria",
                "edit.password.requiredRepeat": "Es necesario repetir la nueva contraseña",

                "edit.profile.success": "Tus datos han sido actualizados correctamente",

                "form.update.title": "Actualizar",
                "form.update.name.title": "Nombre y apellidos actualizados",
                "form.update.name.success": "Tus datos han sido actualizados correctamente",
                "form.update.password.title": "Contraseña actualizada",
                "form.update.password.success": "Tu contraseña ha sido actualizada correctamente",
                "form.errors.passwords": "Las contraseñas no coinciden",
                "form.update.settings.title": "Ajustes de usuario actualizados",
                "form.update.settings.success": "Tus ajustes han sido actualizados correctamente",
                "download.title": "Fichero compartido por {{ username }}",
                "download.message": "El usuario {{ username }} ha compartido un fichero contigo. ¿Deseas descargarlo?",
                "download.accept": "Descargar",
                "download.reject": "Cancelar",
                "share-file.title": "Compartir fichero",
                "share-file.accept": "Compartir",
                "share-file.participants": "Participantes",
                "share-file.file": "Fichero",

                // ========== END: Button Titles ==========

                // ========== START: Modal Texts ========
                "modal.warning.title": "Advertencia",

                "modal.error.email.title": "Error al verificar correo",
                "modal.warning.email.title": "Advertencia",
                "modal.success.email.title": "Correo confirmado",

                "modal.logout.title": "Cerrar Sesión",
                "modal.logout.content": "¿Estás seguro que deseas cerrar sesión?",
                "modal.logout.confirm": "Cerrar Sesión",
                "modal.logout.cancel": "Cancelar",

                "modal.register.success.title": "Registro completado",
                "modal.register.success.description": "Registro completado correctamente. Por favor, compruebe su correo electrónico para verificar su cuenta",

                "modal.forgot.success.title": "Restablecimiento de contraseña",
                "modal.forgot.success.description": "Si el correo electrónico proporcionado pertenece a una cuenta registrada, recibirá un correo con las instrucciones para restablecer su contraseña. Por favor, compruebe su bandeja de entrada y spam",

                "modal.restore.success.title": "Contraseña restablecida",
                "modal.restore.success.description": "La contraseña ha sido restablecida correctamente. Por favor, inicie sesión con su nueva contraseña",

                "modal.warning.email.description_login": "Revisa tú bandeja de correo y espera a que el administrador apruebe tu cuenta. Recibirás un correo cuando tu cuenta sea aprobada",
                "modal.success.email.description_default": "Registro completado correctamente. El equipo administrativo de Sippar se pondrá en contacto contigo para activar tu cuenta",

                "button.modal.close": "Cerrar",
                // ========== END: Modal Texts ==========

                "recording.title": "Grabación en curso",
              }
        }
    },
    lng: "es", // if you"re using a language detector, do not define the lng option
    fallbackLng: "es",
    interpolation: {
        escapeValue: false
    }
}


export const errorTranslations: {
    [key: string]: {
        [key: string]: string
    }
} = {
    "es": {
        "must be a string": "debe ser un texto",
        "must be shorter than or equal to": "debe ser menor o igual a",
        "must be longer than or equal to": "debe ser mayor o igual a",
        "must be longer than": "debe ser mayor a",
        "must be shorter than": "debe ser menor a",
        "must be a number conforming to the specified constraints": "debe ser un número",
        "must be a boolean value": "debe ser un valor booleano",
        "must be a valid email address": "debe ser un correo electrónico válido",
        "must be a valid date string": "debe ser una fecha válida",
        "must be a valid date": "debe ser una fecha válida",
        "must be a valid UUID": "debe ser un UUID válido",
        "must be a valid enum value": "debe ser un valor válido",
        "should not be empty": "no debe estar vacío",
        "must be an URL address": "debe ser una URL válida",
        "must not be greater than": "debe ser menor o igual a",
        "must not be less tha": "debe ser mayor o igual a",
        "must be a UUID": "debe ser identificación válido",
        "must be an email": "debe ser un correo electrónico válido",
        "must contain only letters (a-zA-Z)": "debe contener solo letras (a-zA-Z)",
        "must contain only letters and numbers": "debe contener solo letras y números",
        "must be a valid phone number": "debe ser un número de teléfono válido",
        "characters": "caracteres",
        "The group does not exist": "El grupo no existe",
        "You must be an administrator to delete a group": "Debes ser administrador para eliminar un grupo",
        "You must be an administrator to view groups": "Debes ser administrador para ver los grupos",
        "You must be an administrator to update a group": "Debes ser administrador para actualizar un grupo",
        "A group with this name already exists": "Ya existe un grupo con este nombre",
        "There is already a group with this invitation": "Ya existe un grupo con esta invitación",
        "You must be an administrator to create a group": "Debes ser administrador para crear un grupo",
        "Appointment not found": "Cita no encontrada",
        "Surely the user is already invited": "Seguramente el usuario ya está invitado",
        "You have another appointment at this time": "Tienes otra cita a esta hora",
        "To delete a citation you must be the author of it": "Para eliminar una cita debes ser el autor de la misma",
        "You cannot delete a patient that does not exist": "No puedes eliminar un paciente que no existe",
        "You cannot create a patient with the same ID": "No puedes crear un paciente con el mismo ID",
        "The password is not correct": "La contraseña no es correcta",
        "Surely the group already has the permission": "Seguramente el grupo ya tiene el permiso",
        "This group has reached the subscription limit": "Este grupo ha alcanzado el límite de suscripción",
        "Wrong invitation": "Invitación incorrecta",
    }
}

export function TranslateError(error: string) {

    // get window language:
    const language = navigator.language.split("-")[0];

    const errors = language === 'es' && errorTranslations[language];
    if (errors) {

        Object.keys(errors).forEach((key) => {
            error = error.replace(key, errors[key])
        })

        return error;
    }
    else return error;
}
