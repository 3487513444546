import { useTranslation } from "react-i18next";

import { EditProfileProperties } from "./editProfile.properties";

import './editProfile.styles.scss';
import { LoaderComponent } from "../../../loader/loader.component";
import { useState } from "react";
import { EditProfileNameComponent } from "./editProfile.name";
import { EditProfilePasswordComponent } from "./editProfile.password";
import { EditProfileSettings } from "./editProfile.settings";

export function EditProfileComponent(properties: EditProfileProperties): JSX.Element {
  // translation function
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const [screen, setScreen] = useState<string>(properties.configOption || 'options');

  const [success, setSuccess] = useState<boolean>(false);

  return <div className="EditProfileComponent Modal Right">
    <LoaderComponent status={loading}/>
    <div className="Header">
      {/* {
        screen !== 'options' && <i className='las la-angle-left' onClick={() => {setScreen('options')}}/>
      } */}
      {
        screen === 'options' && <span>{t('edit.profile.title')}</span>
      }
      {
        screen === 'name' && <span>{t('edit.profile.name')}</span>
      }
      {
        screen === 'password' && <span>{t('edit.profile.password')}</span>
      }
      {
        screen === 'other-options' && <span>{t('edit.profile.others')}</span>
      }

        <i className="las la-times" onClick={() => { properties.setScene(undefined) }}></i>
    </div>
    {/* {
      screen === 'options' &&
      <div className="Body-Options">
        <div className="Name-Option" onClick={() => {setScreen('name')}}>
          <i className="las la-user"></i>
          <p>{t('edit.profile.name')}</p>
        </div>
        <div className="Password-Option" onClick={() => {setScreen('password')}}>
          <i className="las la-lock"></i>
          <p>{t('edit.profile.password')}</p>
        </div>
        <div className="Others-Option" onClick={() => {setScreen('other-options')}}>
          <i className="las la-cog"></i>
          <p>{t('edit.profile.others')}</p>
        </div>
      </div>
    } */}
    {
      screen === 'name' && <EditProfileNameComponent
        success={success}
        setSuccess={setSuccess}
        setScreen={setScreen}
        userInfo={properties.userInfo}
        setLoading={setLoading}
        setUser={properties.setUser}
        setScene={properties.setScene}
        />
    }
    {
      screen === 'password' && <EditProfilePasswordComponent
        success={success}
        setSuccess={setSuccess}
        setScreen={setScreen}
        setLoading={setLoading}
        userInfo={properties.userInfo}
        setScene={properties.setScene}
      />
    }
    {
      screen === 'other-options' && <EditProfileSettings
        success={success}
        setSuccess={setSuccess}
        setScreen={setScreen}
        userInfo={properties.userInfo}
        setLoading={setLoading}
        setUser={properties.setUser}
        setScene={properties.setScene}
      />
    }
  </div>
}
