// Properties
import { useEffect, useState } from 'react';
import { ShareFileProperties } from './share-file.properties';

// Styles
import './share-file.styles.scss';
import { useTranslation } from 'react-i18next';


/**
 * Participants Component
 * @description Component to render participants of videocall
 * @param {ParticipantsProperties} properties
 * @returns {JSX.Element}
 */
export function ShareFileComponent(properties: ShareFileProperties): JSX.Element {
  const { t } = useTranslation();
  const [participantsToSend, setParticipantsToSend] = useState<string[]>([]);
  const [fileToSend, setFileToSend] = useState<File | undefined>();
  const [filename, setFilename] = useState<string>('');

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFileToSend(event.target.files[0]);
      setFilename(event.target.files[0].name);
    } else {
      setFileToSend(undefined);
      setFilename('');
    }

    return;
  };

  useEffect(() => {
    console.log(fileToSend);
    console.log(typeof fileToSend);
  }, [fileToSend]);

  const handleSendFile = () => {
    // Download file
    const reader = new FileReader();
    reader.readAsDataURL(fileToSend as Blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      const data = {
        file: base64data,
        filename: filename,
        participants: participantsToSend,
        user: properties.user.id,
        room: properties.room
      };

      // Send file
      properties.socket.emit('file:share', data);

      // Close modal
      properties.setScene(undefined);
    }

    return;
  };

    return <div className="ShareFileComponent Modal">
        <div className='Header'>
            {t('share-file.title')}
            <i className='las la-times last' onClick={() => { properties.setScene(undefined) }}></i>
        </div>
        <div className='Participants'>
          <p>{t('share-file.participants')}</p>
          {
            Object.entries(properties.participants).map(([key, value]) => {
              return <div key={key} className='Reciver' onClick={
                () => {
                  if (participantsToSend.includes(value.user.id)) {
                    setParticipantsToSend(participantsToSend.filter(participant => participant !== value.user.id));
                  } else {
                    setParticipantsToSend([...participantsToSend, value.user.id]);
                  }
                }
              }>
                <div className={`Checkbox ${participantsToSend.includes(value.user.id) ? 'active' : ''}`}>
                    <i className={
                        participantsToSend.includes(value.user.id) ? 'las la-check' : '"las la-times"'
                    }></i>
                    <span>{value.user.name} {value.user.surnames}</span>
                </div>
              </div>
            })
          }
        </div>
        <div className='File'>
          <p>{t('share-file.file')}</p>
          <input type='file' onChange={selectFile} />
        </div>
        <div className='File'>
          <button className='Submit' onClick={handleSendFile} disabled={ !fileToSend || !participantsToSend.length }>{t('share-file.accept')}</button>
        </div>
    </div>
}
