export class LocalStorageHelper {

  /**
   * Remove all items from the local storage
   */
  static async cleanLocalStorage() {
    const localStorageItems = [
      'user',
      'userSettings',
      'canRecord',
      'audio_status',
      'video_status',
      'localCall',
      'audioDeviceId',
      'audioInputDeviceId',
      'backgrounds',
      'backgroundImage',
    ];

    localStorageItems.forEach((item) => {
      localStorage.removeItem(item);
    });

    // Remove videoDeviceId only if the app is running in the browser and not in electron
    // @ts-ignore
    !window.electron && localStorage.removeItem('videoDeviceId');
  }

}
