// Modules
import { useEffect, useState } from "react";

// Properties
import { CallProperties } from "./call.properties"
import { useTranslation } from "react-i18next";
import { CountdownComponent } from "../countdown/countdown.component";
import { UserType } from "../../../../types/user.type";

// Assets
const boopSfx = require('./call.mp3');

/**
 * Call Component
 * @description Component to show the call
 * @param {CallProperties} properties
 * @returns {JSX.Element}
 */
export function CallComponent(properties: CallProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // Get logged user
    const user: UserType = properties.user;

    const [callAnswered, setCallAnswered] = useState(false);
    const [callRejected, setCallRejected] = useState(false);
    const [canRecord, setCanRecord] = useState<boolean>(false);

    useEffect(() => {
      const userGroups = user?.users_groups;

      if (userGroups) {
        const canRecord = userGroups.some((group) => group?.group?.record_activated === false);
        setCanRecord(!canRecord);
      }
    }, []);

    /**
     * Accepted Call
     */
    const acceptedCall = () => {
      properties.setAnsweredCall(true);
      localStorage.setItem('localCall', 'false');
        // emit call accepted
        properties.socket.emit('call:accepted', {
            room: properties.call.room,
            user: properties.user,
            author: properties.call.available,

        })

        // set room to call
        properties.setRoom(properties.call.room)
        setTimeout(() => {
            properties.socket.joinRoom(properties.call.room)
            properties.setCall(undefined)
        }, 1000)
    }

    /**
     * Rejected Call
     */
    const rejectedCall = () => {
      setCallRejected(true);
        // emit call rejected
        properties.socket.emit('call:rejected', {
            room: properties.call.room,
            available: properties.call.available,
        })

        // clean call
        properties.setCall(undefined)
    }

    const ringTone = () => {
      if (callAnswered || callRejected) {
        return;
      }

      // emit audio notification
      const audio = new Audio(boopSfx);
      audio.play();

      setTimeout(() => {
        if (callAnswered || callRejected) {
          return;
        }

        // repeat audio notification
        const audio = new Audio(boopSfx);
        audio.play();
      }, 1000);
    }

    useEffect(() => {
      ringTone();

      // Wait 2 seconds to repeat audio notification
      const ringInterval = setInterval(() => {
        ringTone();
      }, 3000);

        return () => {
            clearInterval(ringInterval);
        }

    }, [callAnswered, callRejected]);

    return <div className="CallComponent Modal" style={{
        right: 0,
        left: 'auto'
    }}>
        <div className='Header'>
            {t('call.title')} de {properties?.call?.available?.user?.name} {properties?.call?.available?.user?.surnames}
        </div>

        <div style={{
            color: '#666',
            padding: 25, fontSize: 18, marginTop: 25, borderLeft: '4px solid #ddd', marginLeft: 25
        }}>
            {t('call.message')}

            {
              canRecord && <div className="rec-info">
                <i className="las la-exclamation-triangle"></i>
                <p>{t('call.rec.info')}</p>
              </div>
            }
        </div>

        <div style={{
            margin: 25, marginTop: 0
        }}>
            <button className="Submit" disabled={!properties.devicesLoaded} onClick={acceptedCall}>
                {t('call.accept')}
                {
                    user.user_setting?.auto_call && properties.call !== undefined ? (
                    properties.devicesLoaded && <CountdownComponent time={user.user_setting?.auto_call_timeout} onFinish={acceptedCall} />
                    ) : ("")
                }
            </button>
            <div className="Submit secondary" onClick={rejectedCall}>
                {t('call.reject')}
            </div>
        </div>
    </div>
}
