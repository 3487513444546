// Modules
import { useState } from "react"

// Components
import { ControlComponent } from "../controls/controls"
import { ChatComponent } from "../room/chat/chat.component"

// Styles
import './sections.styles.scss'

// Types
import { SectionProperties } from "./sections.properties"

/**
 * Section Component
 * @description Component to render sections of videocall
 * @param {SectionSettings} settings
 * @returns {JSX.Element}
 */
export function SectionComponent(settings: SectionProperties): JSX.Element {

    // sections to render
    const [section, setSection] = useState<string | undefined>()

    return <>

        {/* bar control component */}
        <ControlComponent
            setScene={(section: string | undefined) => settings.setScene(section)}
            setLocalCall={settings.setLocalCall}
            localCall={settings.localCall}
            lastMessages={settings.lastMessages}
            setLastMessages={settings.setLastMessages}
            scene={settings.scene}
            finishCall={settings.finishCall}
            messages={settings.messages}
            user={settings.user}
            userSettings={settings.userSettings}
            room={settings.room}
            setUser={settings.setUser}
            participants={settings.participants}
            numberParticipants={settings.numberParticipants}
            availables={settings.availables}
            setLayout={settings.setLayout}
            layout={settings.layout}
            socket={settings.socket}
            audioStatus={settings.audioStatus}
            videoStatus={settings.videoStatus}
            setVideoStatus={settings.setVideoStatus}
            setAudioStatus={settings.setAudioStatus}

            deviceInstance={settings.deviceInstance}
            addStream={settings.addStream}
            removeShareScreen={settings.removeShareScreen}

            answeredCall={settings.answeredCall}
            setAnsweredCall={settings.setAnsweredCall}

            clickedUsers={settings.clickedUsers}
            setClickedUsers={settings.setClikedUsers}

            showLogout={settings.showLogout}
            setShowLogout={settings.setShowLogout}
        />

        {
            // chat component
            section === 'chat' && <ChatComponent
                room={settings.room}
                setSection={() => setSection('')}
                messages={settings.messages}
                sendMessage={(message: string) => {

                    settings.sendMessage(message)

                }}
            />
        }
    </>

}
