import { useState } from 'react';
import './userInfo.styles.scss';


export interface UserInfoComponentProps {
  name: string;
  setShowLogout: (showLogout: boolean) => void;
  setConfigOption: (configOption: string) => void;
  setScene: (scene: string | undefined) => void;
}

export function UserInfoComponent(properties: UserInfoComponentProps) {
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <div className="UserInfoComponent" onClick={toggleMenu}>
        <div className='userInfo'>
          {properties.name} <i className="las la-user-circle"></i>
        </div>
        {isMenuVisible && (
          <div className='dropdown-menu'>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene('edit-profile');
                properties.setConfigOption('name');
              }
            }>
              <i className="las la-user"></i>
              <span>Editar Perfil</span>
            </div>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene('edit-profile');
                properties.setConfigOption('password');
              }
            }>
              <i className="las la-lock"></i>
              <span>Cambiar contraseña</span>
            </div>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene('edit-profile');
                properties.setConfigOption('other-options');
              }
            }>
              <i className="las la-cog"></i>
              <span>Ajustes de usuario</span>
            </div>
            <div className='dropdown-item logout' onClick={() => properties.setShowLogout(true)}>
              <i className="las la-power-off"></i>
              <span>Cerrar sesión</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
