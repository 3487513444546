// Properties
import { DownloadFileProperties } from "./download-file.properties"
import { useTranslation } from "react-i18next";

/**
 * DownloadFile Component
 * @description Component to show the popup to download a file
 * @param {DownloadFileProperties} properties
 * @returns {JSX.Element}
 */
export function DownloadFileComponent(properties: DownloadFileProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    const downloadFile = () => {
      const base64Data = properties.file;
      const fileName = properties.filename;

      // Download file
      const element = document.createElement('a');
      element.setAttribute('href', base64Data);
      element.setAttribute('download', fileName);
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      properties.setScene(undefined);
    };

    const rejectDownload = () => {
      properties.setScene(undefined);
    };

    return <div className="DowloadFileComponent Modal" style={{
        right: 0,
        left: 'auto'
    }}>
        <div className='Header'>
            {t('download.title', { username: properties.username })}
        </div>

        <div style={{
            color: '#666',
            padding: 25, fontSize: 18, marginTop: 25, borderLeft: '4px solid #ddd', marginLeft: 25
        }}>
            {t('download.message', { username: properties.username })}
        </div>

        <div style={{
            margin: 25, marginTop: 0
        }}>
            <button className="Submit" onClick={downloadFile}>
                {t('download.accept')}
            </button>
            <div className="Submit secondary" onClick={rejectDownload}>
                {t('download.reject')}
            </div>
        </div>
    </div>
}
